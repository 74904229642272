// Cookiefirst
import config from "../config";

export default {

	methods: {
		putCookieFirstScriptInPlace(){
			console.log("--- COOKIEFIRST --------------------------------------");
			console.log("Deploying COOKIEFIRST");
			console.log("-----------------------------------------");
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);

			/* Put the code for this action here */
			script.setAttribute('data-cookiefirst-key',`af1b1e06-b19f-440c-b4f7-b63c80d6245c`);

			document.body.prepend(script);
		}
	},
	beforeMount() {
		if(config.isWebsiteInProduction && !this.isPnGAction){
			this.putCookieFirstScriptInPlace();
		}
	}

}
